import { defineModule } from '../utils/helpers';

declare const Calendly: {
  initInlineWidget: (options: {
    url: string;
    parentElement: HTMLElement;
    resize?: boolean;
  }) => void;
};
const getElements = () => ({
  calendlyEl: document.querySelector('#calendly-embed') as HTMLElement | null,
});

const isOlderThan18 = (day: number, month: number, year: number): boolean => {
  const today = new Date();
  const eighteenYearsAgo = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate(),
  );
  const birthDate = new Date(year, month - 1, day);

  return birthDate <= eighteenYearsAgo;
};

const getTouringGuide = (day: number, month: number, year: number) => {
  const { calendlyEl } = getElements();
  if (!calendlyEl) return null;

  const adultsUrl = calendlyEl.dataset.calendlyAdults;
  const minorsUrl = calendlyEl.dataset.calendlyMinors;

  return isOlderThan18(day, month, year) ? adultsUrl : minorsUrl;
};

const initInlineWidget = (
  day: number,
  month: number,
  year: number,
  email: string,
  firstName: string,
  lastName: string,
  guestEmail: string,
) => {
  const { calendlyEl } = getElements();
  if (!calendlyEl) return;

  calendlyEl.innerHTML = '';

  const touringGuideUrl = getTouringGuide(day, month, year);
  if (!touringGuideUrl) return;

  Calendly.initInlineWidget({
    url: `https://calendly.com/${touringGuideUrl}?hide_gdpr_banner=1&email=${email}&name=${firstName}%20${lastName}&guests=${guestEmail}`,
    parentElement: calendlyEl,
    resize: true,
  });
};

const onCalendlyMessage = (e: Event) => {
  const messageEvent = e as MessageEvent;
  if (!messageEvent.data?.event?.startsWith('calendly')) return;

  if (messageEvent.data.event !== 'calendly.event_scheduled') return;

  const submitButton = document.querySelector(
    'button[type="submit"]',
  ) as HTMLButtonElement | null;
  const hiddenUUIDField = document.querySelector(
    '.gfield--input-type-hidden input[value="Calendly UUID"]',
  ) as HTMLInputElement | null;

  if (!submitButton || !hiddenUUIDField) return;

  hiddenUUIDField.value = messageEvent.data.payload.invitee.uri;
  submitButton.click();
};

const onCalendlyPageLoaded = (
  event: JQuery.TriggeredEvent,
  formId: string,
  currentPage: string,
) => {
  if (currentPage !== '4') return;

  const getFieldByLabel = (labelText: string) => {
    const label = [
      ...document.getElementsByClassName('gform-field-label'),
    ].find((l) => l.textContent?.trim() === labelText) as
      | HTMLLabelElement
      | undefined;
    return label?.control as HTMLInputElement | null;
  };

  const dayField = document.querySelector(
    '.gfield_date_dropdown_day select',
  ) as HTMLSelectElement;
  const monthField = document.querySelector(
    '.gfield_date_dropdown_month select',
  ) as HTMLSelectElement;
  const yearField = document.querySelector(
    '.gfield_date_dropdown_year select',
  ) as HTMLSelectElement;
  const emailField = getFieldByLabel('Emailadres*');
  const firstNameField = getFieldByLabel('Voornaam*');
  const lastNameField = getFieldByLabel('Achternaam*');
  const guestEmailField = getFieldByLabel('Emailadres');
  const contactPermission = getFieldByLabel(
    'Script mag contact opnemen met mijn contactpersoon.',
  );

  const handleInputChange = () => {
    const day = parseInt(dayField?.value || '', 10);
    const month = parseInt(monthField?.value || '', 10);
    const year = parseInt(yearField?.value || '', 10);
    const email = emailField?.value || '';
    const firstName = firstNameField?.value || '';
    const lastName = lastNameField?.value || '';
    const guestEmail =
      guestEmailField && contactPermission?.checked
        ? guestEmailField.value
        : '';

    if (day && month && year && email && firstName && lastName) {
      initInlineWidget(
        day,
        month,
        year,
        email,
        firstName,
        lastName,
        guestEmail,
      );
    }
  };

  handleInputChange();
};

export default defineModule(
  () => {
    const { calendlyEl } = getElements();
    if (!calendlyEl) return;

    document.addEventListener('DOMContentLoaded', () => {
      jQuery(document).on('gform_page_loaded', onCalendlyPageLoaded);
      window.addEventListener('message', onCalendlyMessage);
    });
  },
  () => {
    const { calendlyEl } = getElements();
    if (!calendlyEl) return;

    jQuery(document).off('gform_page_loaded', onCalendlyPageLoaded);
    window.removeEventListener('message', onCalendlyMessage);
  },
);
