import Cookies from 'js-cookie';
import { defineModule } from '../../utils/helpers';
import { toggleModal } from '.';

const getElements = () => ({
  popUpModalElement: document.querySelector('[data-modal="pop-up"]'),
  popUpElements: document.querySelectorAll<HTMLElement>('[data-pop-up-id]'),
  popUpDismisButtons: document.querySelectorAll<HTMLElement>(
    '[data-dismiss-modal="pop-up"]',
  ),
});

const checkPopUps = () => {
  const { popUpElements } = getElements();

  return [...popUpElements].some((popUp) => {
    const { popUpId } = popUp.dataset;

    if (!Cookies.get(`pop-up-${popUpId}`)) {
      popUp.classList.remove('!hidden');
      toggleModal('pop-up', true);
      return true;
    }
    return false;
  });
};

const dismissPopUp = ({ currentTarget }: Event) => {
  if (!(currentTarget instanceof HTMLElement)) return;

  const { popUpElements } = getElements();
  const popUp = [...popUpElements].find(
    (el) => !el.classList.contains('!hidden'),
  );

  if (!popUp) return;

  const { popUpId, popUpExpires } = popUp.dataset;

  popUp.classList.add('!hidden');

  const expires = Number(popUpExpires ?? 0);
  const options = expires ? { expires } : {};
  Cookies.set(`pop-up-${popUpId}`, 'dismissed', options);

  toggleModal('pop-up', checkPopUps());
};

export default defineModule(
  () => {
    const { popUpModalElement, popUpDismisButtons } = getElements();
    if (!popUpModalElement) return;

    popUpModalElement.classList.remove('hidden');

    popUpDismisButtons.forEach((element) => {
      element.addEventListener('click', dismissPopUp);
    });

    checkPopUps();
  },
  () => {
    const { popUpDismisButtons } = getElements();

    popUpDismisButtons.forEach((element) => {
      element.removeEventListener('click', dismissPopUp);
    });

    toggleModal('pop-up', false);
  },
);
